import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import Login from "./components/login";
import SignUp from "./components/register";
import ImageUpload from "./components/ImageUpload";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "./components/firebase";
import PrivateRoute from "./components/PrivateRoute"; // Import PrivateRoute
import Rashidprofile from "./components/rashidprofile"
import Limraprofile from "./components/limraprofile"
import Rejaulprofile from "./components/rejaulprofile"
import Makhmaliprofile from "./components/makhmaliprofile"
import Masteradmin from "./components/masteradmin";


function App() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner
  }

  return (
    <Router>
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/limraprofile" element={user ? <Limraprofile /> : <Navigate to="/login" />} />
              <Route path="/rejaulprofile" element={user ? <Rejaulprofile /> : <Navigate to="/login" />} />
              <Route path="/makhmaliprofile" element={user ? <Makhmaliprofile /> : <Navigate to="/login" />} />
              <Route path="/rashidprofile" element={user ? <Rashidprofile /> : <Navigate to="/login" />} />
              <Route path="/masteradmin" element={user ? <Masteradmin /> : <Navigate to="/login" />} />
              <Route path="/ImageUpload" element={user ? <ImageUpload /> : <Navigate to="/login" />} />
            </Routes>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
